// Validators to validate various data types from inputs.
// These are typically passed as a 'validator' proprty to an Input
// component.

// Return true or false based on conditions from passed value. Typically the
// value of the Input component.

export const DEFECT_BARCODE_MARKER = '-DEF';

export const barcodeValidator = (val) => {
	return val && !isNaN(val) && val.length > 0;
};

export const numberValidator = (val) => {
	return val && !isNaN(val) && val.length > 0;
};

export const numberValidatorAllowUndefined = (val) => {
	return val == undefined || (val && !isNaN(val) && val.length > 0);
};

export const pctValidator = (val) => {
	if (val && val.length > 0) {
		if (!isNaN(val.replace('%', '')) && parseFloat(val) < 100 && parseFloat(val) >= 0) {
			return true;
		}
	}
	return false;
};

export const pctValidatorUncapped = (val) => {
	if (val && val.length > 0) {
		if (!isNaN(val.replace('%', '')) && parseFloat(val) >= 0) {
			return true;
		}
	}
	return false;
};

export const keywordValidator = (val) => {
	if (val && !isNaN(val) && val.length >= 2 && !val.includes('.')) {
		return true;
	}

	return false;
};

export const emailValidator = (val) => {
	return String(val)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const phoneNumberValidator = (val) => {
	var test = String(val)
		.toLowerCase()
		.match(/^[\+]?[0-9]{3}?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
	return test;
};

export const validateBarcodeFormat = (val, validator) => {
	if (!val) return { success: false };
	switch (validator) {
		case '12 numbers':
			// return true for defect products
			if (isDefectBarcode(val) && val?.length === 12 + DEFECT_BARCODE_MARKER?.length) return { success: true };

			// else validate barcode
			if (val.length != 12) return { success: false, message: 'Barcode must be 12 numbers' };
			if (isNaN(val)) return { success: false, message: 'Barcode must be 12 numbers' };
			return { success: true };
	}

	return { success: true };
};

export const isDefectBarcode = (val) => {
	// Check if the value ends with DEFEECT_BARCODE_MARKER
	if (val?.slice(-DEFECT_BARCODE_MARKER?.length) === DEFECT_BARCODE_MARKER) return true;
	return false;
};

export const isValidBarcode = (val) => {
	// Reject any barcodes that have special characters in them, except hyphens or spaces or +
	if (val?.match(/[^a-zA-Z0-9\- +]/g)) return false;
	return true;
};

export const isPhoneNumber = (val) => {
	return phoneNumberValidator(val);
};

export const isEmail = (val) => {
	return emailValidator(val);
};

export const isUUID = (val) => {
	if (!val) return false;
	const regex = /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-(8|9|a|b|A|B)[0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$/;
	return regex.test(val);
};

export const isUUIDorNull = (val) => {
	if (!val) return true;
	const regex = /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-(8|9|a|b|A|B)[0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$/;
	return regex.test(val);
};

export const isValidBiddingKeyword = (val) => {
	if (!val?.length) return false;
	return val.length > 0 && val.length < 100;
};

export const sanitizeUUID = (val) => {
	if (!val) return undefined;
	if (isUUID(val)) return val;
	throw Error(`Invalid UUID: ${val}`);
};

export const isDate = (val) => {
	if (!val) return false;
	return !isNaN(Date.parse(val));
};

export const isNumber = (val) => {
	if (val === 0) return true;
	if (!val) return false;
	return !isNaN(val);
};

export const isInteger = (val) => {
	if (val === 0) return true;
	if (!val) return false;
	return !isNaN(val) && Number.isInteger(val);
};

export const isURL = (val) => {
	if (!val) return false;

	const match = val.match(
		/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/gi,
	);

	return match != undefined && match != null;
};

export const isArray = (val) => {
	return Array.isArray(val);
};

export const sanitizeBoolean = (val) => {
	if (typeof val === 'boolean') return val;
	if (val?.toLowerCase() === 'true') return true;
	if (val?.toLowerCase() === 'false') return false;
	throw Error(`Invalid boolean value: ${val}`);
};

export const sanitizeDate = (val) => {
	if (!val) return undefined;
	if (isDate(val)) return val;
	throw Error(`Invalid date: ${val}`);
};
